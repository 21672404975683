
$(document).ready(function(){
    $('.project-slider').owlCarousel({
        
        loop:true,
        autoWidth:true,
        items:4,
        pagination:true,
        responsive:{
            0:{
                margin:22
            },
            850:{
                margin:44
            }
        }
    });
    

    $('.careerca').owlCarousel({
        loop:true,
        items:1,
        nav:true,
        pagination:true,
        autoplay:false,
    });

    
    $('.hamburger').on('click', function () {
        $('.hamburger-animation').toggleClass('open');
        event.preventDefault();
        if ( $(this).hasClass("open") ) {
            $(".hamburger-menu").stop().animate({right:"-220px"}, 200);                            
        } else {
            $(".hamburger-menu").stop().animate({right:"0px"}, 200);
        }
        $(this).toggleClass("open");
        return false;
      });
      
      $(document).ready(function() {
        $('li.menu-item-has-children > a').click(function(e) {
          e.preventDefault();
          $(this).siblings('ul').toggleClass('show');
          e.stopPropagation();
        });
        if ($(window).width() < 1190) {
        $('.mobilemenu').click(function(e) {
            e.preventDefault();
            $(".main-navigation").toggleClass('show');
            e.stopPropagation();
          });
        $('html').click(function(e) {
            if (!$(e.target).is('.main-navigation, .main-navigation *')) {
                $('.main-navigation').removeClass('show');
            }
        });
        }

        $('html').click(function(e) {
            if (!$(e.target).is('ul.show, ul.show *')) {
              $('ul.show').removeClass('show');
            }
            if (!$(e.target).is('.opp_li li.show, .opp_li li.show *')) {
                $('.opp_li li.show').removeClass('show');
              }
          });



        $('.opp_li li').click(function() {
            // Check if the clicked li already has the 'show' class
            if ($(this).hasClass('show')) {
              // Remove the 'show' class from the clicked li
              $(this).removeClass('show');
            } else {
              // Add the 'show' class to the clicked li
              $(this).addClass('show');
              
              // Remove the 'show' class from any nearby li elements
              $(this).siblings().removeClass('show');
            }
          });
          // $('div.opportunity-overlay').click(function(e) {
          //   e.preventDefault();
          //   e.stopPropagation();

          // }); 

          


      });
      $(".learn-mo").click(function(){
        $(this).closest(".flip-card-inner").addClass("show");
      });
      $(".close-mo").click(function(){
        $(this).closest(".flip-card-inner").removeClass("show");
      });
});

$(document).ready(function(){

  $('.career-postss').owlCarousel({
    loop:true,
    items:3,
    nav:true,
    pagination:true,
    autoplay:true,
    margin:20,
    autoplayHoverPause:true,
    responsive:{
  
      0:{
          items:1,
      },
      767:{
          items:1, 
      },
      1080:{
          items:4,
      }
  }
  });
  });